import React from "react";
import { HeroLayout } from "../components/hero-layout";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { getQueryParam } from "../utils/query";
import { rhythm } from "../utils/typography";
import { LandingApiClient } from "../imports/landing/types/LandingApiClient";

export const NewCompanyPage = ({ location }) => {
  const [error, setError] = React.useState();
  const [token, setToken] = React.useState();
  const [orgs, setOrgs] = React.useState();
  React.useEffect(() => {
    const token = getQueryParam("token", location);

    setError(getQueryParam("error", location));
    setToken(token);

    const api = new LandingApiClient(`${process.env.API_URL}/api`);

    api.getOrgsForEmail({ token: token }).then((res) => {
      setOrgs(res.orgs);
    });

    // TODO(@anton): Add error handling
    // TODO(@anton): Show a message when there's no orgs for user (e.g. when user installed to google domain, )
    // TODO(@anton): Add loading indicator when we retrieve the list of orgs
  }, [location]);

  function OrgList({ orgs }) {
    if (!orgs || orgs.length === 0) {
      return null;
    }

    const listItems = orgs.map((org) => (
      <div>
        <a href={org.url} key={org.url}>
          {org.name}
        </a>
        <br></br>
      </div>
    ));

    return (
      <div className="form-container new-company">
        <h2
          className="mdc-typography--headline4 headline"
          style={{ marginBottom: rhythm(1) }}
        >
          Link this integration with your Remeet account
        </h2>
        {listItems}
      </div>
    );
  }

  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Login to your Remeet workspace"} />
      <HeroLayout>
        <div className="form-container new-company">
          If you don't see your Remeet workspace below, please double-check that
          you have admin permissions and correct email is used.
          <OrgList orgs={orgs} />
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default NewCompanyPage;
